import React, { useState, ChangeEvent, useContext } from 'react';
import { useStaticQuery, graphql, navigate } from 'gatsby';
import { useFormik, FormikValues } from 'formik';
import * as Yup from 'yup';
import { GatsbyImage } from 'gatsby-plugin-image';
import MaskedInput from 'react-text-mask';
import { CreditcardContext } from '../../../context/creditcard.context';
import { LangContext } from '../../../context/lang.context';
import { CompanyContext } from '../../../context/company.context';
import {delay, isConsecutive, isTrue} from '../../../utils/functions';
import {MASK_EXPIRATION_DATE, MASK_CHAR_CARD, createMask, MASK_CARD} from '../../../utils/masks';
import {
  navigateToSuccess,
  navigateToFailed,
  navigateToAbsolutePath,
  navigateToError,
  navigateToCustomPage,
  getMultiCompanyPath,
} from '../../../utils/navigate';
import { NewCardRequest } from '../../../models/api/request';
import { ValidateParams, ValidateCardParams } from '../../../models/tokenex';
import { validInternationalNameRegex } from '../../../utils/regexs';
import creditCardService from '../../../services/endpoints';
import pages from '../../../constants/pages.json';
import { FormProps } from '../../../components/forms/models';
import Button from '../button/button';
import { currencyFormatter } from '../../../utils/formatters';
import { Field } from './field/field';
import { Switch } from '../switch/switch';
import { H2, H4, P } from '../typography';
import Modal from '../modal';
import {ResultTokenizer} from "@/context/models";


const regexMaskCard = new RegExp(`${MASK_CHAR_CARD}|\\s`, 'gi');
let dataTokenex: any = {};
let dataCardNumbers: { firstSix?: string; lastFour?: string } = {};
let reqName: string = '';
let reqType: string = '';

const REGEXP_VISA = /^4[0-9]{3}-?[0-9]{4}-?[0-9]{4}-?[0-9]{4}$/;
const REGEXP_MASTERCARD = /^5[1-5][0-9]{2}-?[0-9]{4}-?[0-9]{4}-?[0-9]   {4}$/;
const REGEXP_AMEX = /^3[47][0-9-]{16}$/;

const FormNewCard = ({ token, payable, paymentMethods }: FormProps & { token: string; paymentMethods?: {}[] }) => {
  const { getPublicURL, currency, clientId } = useContext(CompanyContext);
  const { infoUser, loading, setLoading } = useContext(CreditcardContext);
  const { t } = useContext(LangContext);
  const [cardType, setCardType] = useState<string | undefined>('');
  const [countCvv, setCountCvv] = useState<3 | 4>(3);
  const [showErrorIframe, setShowErrorIframe] = useState<boolean>(false);
  const [cardNumberFocused, setCardNumberFocused] = useState<boolean>(false);
  const [isMethodAccepted, setIsMethodAccepted] = useState<boolean>(false);
  const [submitted, setSubmitted] = useState<boolean>(false);
  const [isBinAccepted, setIsBinAccepted] = useState<boolean>(true);
  const [formIsDirty, setFormIsDirty]  = useState<boolean>(false);



  const data = useStaticQuery(graphql`
    query {
      allI18NJson {
        nodes {
          locale
          clientId
          FORM {
            PAYMENT_METHODS
            CARD_NUMBER_TITLE
            CARD_NUMBER_PLACEHOLDER
            DEBIT_CARD_TITLE
            CREDIT_CARD_TITLE
            EXPIRATION_CARD_TITLE
            EXPIRATION_CARD_PLACEHOLDER_1
            EXPIRATION_CARD_PLACEHOLDER_2
            SECRET_CARD_TITLE
            SECRET_CARD_PLACEHOLDER
            CARD_OWNER_TITLE
            CARD_OWNER_PLACEHOLDER
            SAVE_PRIMARY_CARD_TITLE
            ADD_CARD_BUTTON_TITLE
            PAY_BUTTON_TITLE
            SAVE_CARD_CHECKBOX_TITLE
            VALIDATION {
              BANK_EMPTY_OPTION
              CARD_CODE_CHARACTER_3
              CARD_CODE_CHARACTER_4
              CARD_CODE_CHARACTER_REQUIRED
              CARD_EXPIRATION_DATE_INVALID
              CARD_EXPIRATION_DATE_REQUIRED
              CARD_NAME_MAX_CHARACTER
              CARD_NAME_MIN_CHARACTER
              CARD_NAME_RIQUIRED
              CARD_TYPE_RIQUIRED
              CARD_TYPE
              INVALID_CARD_NUMBER
              INVALID_CARD_OWNER
              NOT_ACCEPTED_CARD
              NOT_ACCEPTED_BINS
            }
          }
          MODAL_ADD {
            CVV
            CVV_TITLE
            CVV_TITLE_AMEX
          }
        }
      }
      backCardModal: file(relativePath: { eq: "back-card.png" }) {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED, width: 1000)
        }
      }

      backCardAmexModal: file(relativePath: { eq: "back-card-amex.png" }) {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED, width: 1000)
        }
      }

      UnknownCard: file(relativePath: { eq: "cards/creditcard.png" }) {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED, width: 50)
        }
      }

      VisaCard: file(relativePath: { eq: "cards/visa.png" }) {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED, width: 50)
        }
      }

      AmexCard: file(relativePath: { eq: "cards/amex.png" }) {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED, width: 50)
        }
      }

      MaestroCard: file(relativePath: { eq: "cards/maestro.png" }) {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED, width: 50)
        }
      }

      MasterCard: file(relativePath: { eq: "cards/mastercard.png" }) {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED, width: 50)
        }
      }

      InvalidCard: file(relativePath: { eq: "cards/invalid.png" }) {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED, width: 50)
        }
      }
    }
  `);


  const validateCard = async () => {
     const cardNumber = formik.values.cardNumber;
     if (cardNumber == undefined || cardNumber.length < 6) {
       return false;
     }

    var firstSix = cardNumber.substring(0,6);
        try {
          setIsBinAccepted(true)
          const resultBin = await creditCardService.getBinValidation(firstSix, token);
          const { brand, name: paymentMethodName, banks, type, cvvSize, errorCode } = resultBin;

          if (errorCode === 'FGS1310') {
            setIsBinAccepted(false);
            throw new Error('Bins not valid');
          }

          setCardType(brand ?? 'unknown');
          setCountCvv(cvvSize as 3 | 4);
          reqName = paymentMethodName;
          reqType = type.toUpperCase();
        } catch {
          setShowErrorIframe(true);
          return false;
        }
    return true;
  };

  const submitCard = async ({
    ota_id = '',
  }: {
    ota_id?: string;
  }) => {
    const { cardNumber, expirationMonth, expirationYear, bankCode, bankName, cardType, cardOwner, secretCode } = dataTokenex;

    const paramsRequest: NewCardRequest = {
      card: {
        is_add_card: undefined,
        security_code: secretCode || '',
        holder_name: `${cardOwner}`.trim(),
        token: `${ota_id}`.trim(),
        expiration_month: +expirationMonth,
        expiration_year: +expirationYear,
        payment_method_name: `${reqName}`.trim(),
        last_four_digits: `${cardNumber}`.substring(`${cardNumber}`.length-4).trim(),
        save_card: formik.values.saveCard,
      },
    };

    const brands = ['VISA', 'AMEX', 'MAESTRO', 'MASTER'];
    const realType = brands.find((typeCard: string) => cardType?.toUpperCase().includes(typeCard)) ?? '';
    let state = {
      lastFourNumbersCard: `${cardNumber}`.substring(`${cardNumber}`.length-4).trim(),
      typeCard: realType,
      token,
      payable,
    };

    try {
      setLoading?.(true);
      const response = await creditCardService.userNewCard(
        paramsRequest,
        { token },
        payable || infoUser.isOrchestation
      );

          navigateToAbsolutePath(`/${response.url}`, { state: { ...state, payable: payable } });

    } catch ({ errorCode }) {
      let finalState: object;
      if (errorCode === 'FGS1303') {
        finalState = { ...state, from: pages.FAILED_PAGE.ADDED_NOT_PAID };
      } else if (['FGS1301', 'FGS1307'].includes(errorCode)) {
        finalState = { ...state, from: pages.FAILED_PAGE.DUPLICATED_CARD };
      } else if (errorCode === 'FGS1302') {
        finalState = { ...state, from: pages.FAILED_PAGE.BAD_ASSOCIATION_CARD };
      } else if (errorCode === 'FGS1311') {
        finalState = { ...state, from: pages.FAILED_PAGE.DISALLOWED_HOLDER_NAME, token };
      } else {
        finalState = { ...state, from: pages.FAILED_PAGE.NOT_ADDED_NOT_PAID };
      }
      return navigateToFailed({ state: finalState, callback: () => setLoading?.(false) })?.();
    }
  };

  // Prepare formik rules
  const formik = useFormik({
    initialValues: {
      cardNumber: '',
      cardOwner: '',
      cardType: '',
      expirationDate: '',
      secretCode: '',
      saveCard: true,
    },
    validateOnChange: true,
    validateOnBlur: true,
    validationSchema: Yup.object({
      cardNumber: Yup.string()
          .min(16, "Ingresa el número de tarjeta correctamente")
          .max(16, "Ingresa el número de tarjeta correctamente")
          .test('cardType','Tarjetas Amex no soportada',
              function() {
                return cardType !='AMEX';
              })
          .test('cardType','Tipo de tarjeta no soportado', function() {
            return validateCard();
          })
          .test('cardType','Tipo de tarjeta no soportado', function() {
            return isBinAccepted;
          })
          .required('Ingresa el número de tarjeta correctamente'),
      cardOwner: Yup.string()
        .min(3, t(data).FORM.VALIDATION.CARD_NAME_MIN_CHARACTER)
        .max(30, t(data).FORM.VALIDATION.CARD_NAME_MAX_CHARACTER)
        .matches(validInternationalNameRegex, t(data).FORM.VALIDATION.INVALID_CARD_OWNER)
        .required(t(data).FORM.VALIDATION.CARD_NAME_RIQUIRED),
      expirationDate: Yup.string()
        .required(t(data).FORM.VALIDATION.CARD_EXPIRATION_DATE_REQUIRED)
        .validExpirationDate(t(data).FORM.VALIDATION.CARD_EXPIRATION_DATE_INVALID),
      secretCode: Yup.string()
        .min(countCvv, t(data).FORM.VALIDATION[`CARD_CODE_CHARACTER_${countCvv}`])
        .required(t(data).FORM.VALIDATION.CARD_CODE_CHARACTER_REQUIRED),
    }),
    onSubmit: async (values: FormikValues) => {
      setSubmitted(true);
      const { cardNumber, cardOwner, expirationDate, secretCode } = values;
      const [expirationMonth, expirationYear] = expirationDate.split('/');
      dataTokenex = {
        cardNumber,
        cardOwner,
        cardType,
        expirationMonth,
        expirationYear,
        secretCode,
      };

      await delay(100);
      const errors = await formik.validateForm();

      //@todo We should validate the credit card
      if (Object.keys(errors).length === 0) {
        try{
          const { token, signature }: ResultTokenizer = await creditCardService.postToTokenizer(cardNumber,expirationMonth, expirationYear,infoUser.ota_id);
          submitCard({ota_id:token});
        }catch (e) {
          console.error(e);
          if (e && formik.isSubmitting) navigateToError()?.();
        }
      }
    },
  });

  const getTypeCard = (type: string = '') => {
    const brands = ['VISA', 'AMEX', 'MAESTRO', 'MASTER'];
    const realType = brands.find((typeCard: string) => type.toUpperCase().includes(typeCard)) ?? '';
    const typeCard = `${realType.charAt(0).toUpperCase()}${realType.slice(1).toLowerCase()}` || 'Unknown';
    return (
      <GatsbyImage
        className="w-6"
        image={data[`${typeCard || 'Unknown'}Card`].childImageSharp.gatsbyImageData}
        alt="Card"
      />
    );
  };

  // don't even bother to show the form while requests are being resolved
  if (loading) {
    return null;
  }

  // conditions for card number field warnings
  let cardNumberWarning = '';
  if (showErrorIframe) {
    if (!isBinAccepted) {
      cardNumberWarning = t(data).FORM.VALIDATION.NOT_ACCEPTED_BINS;
    }
    if (!isMethodAccepted) {
      cardNumberWarning = t(data).FORM.VALIDATION.NOT_ACCEPTED_CARD;
    }
    if (submitted && !(formik.isValid && formik.dirty)) {
      cardNumberWarning = t(data).FORM.VALIDATION.INVALID_CARD_NUMBER;
    }
  }

  const [modalOpen, setModalOpen] = useState<boolean>(false);

    const setWarning = (field: string) =>
    formik?.touched[field] &&
    typeof formik?.errors[field] === 'string' &&
    formik?.errors[field].length > 0 &&
    (formik.errors[field] as string);

  return (
    <form className="flex flex-auto flex-col justify-between" autoComplete="false" onSubmit={formik.handleSubmit}>
      <section>
        <Field
          key="cardNumber"
          label={t(data).FORM.CARD_NUMBER_TITLE}
          warning={setWarning('cardNumber')}
          required
          focused={cardNumberFocused}
        >

          <div className="-mb-1 flex items-center">
            <span className="pr-1 pt-1">{getTypeCard(cardType)}</span>
            <div className="h-6 w-full pl-1" >
              <MaskedInput
                  className="text-fill-primary w-full bg-transparent font-body text-base leading-tight text-primary focus:outline-none focus-visible:outline-none"
                  value={formik.values.cardNumber}
                  mask={createMask('NUMBER', 16)}
                  guide={false}
                  inputMode="numeric"
                  onChange={(e) => {
                    const cleanValue = e.target.value.replace(/\s+/g, '');
                    formik.setFieldValue('cardNumber', cleanValue);
                  }}
                  onBlur={() => formik.setFieldTouched('cardNumber', true)}
              />
            </div>
          </div>
        </Field>

        <section className="flex gap-2">
          <div className="w-1/2">
            <Field
              key="expirationDate"
              label={t(data).FORM.EXPIRATION_CARD_TITLE}
              warning={setWarning('expirationDate')}
              required
            >
              <MaskedInput
                className="text-fill-primary w-full bg-transparent font-body text-base tabular-nums leading-tight text-primary placeholder:text-gray-400 focus:outline-none focus-visible:outline-none"
                placeholder="MM/AA"
                value={formik.values.expirationDate}
                mask={MASK_EXPIRATION_DATE}
                inputMode="numeric"
                onChange={formik.handleChange('expirationDate')}
                onBlur={() => formik.setFieldTouched('expirationDate', true)}
                style={{
                  WebkitTextFillColor: 'inherit',
                }}
              />
            </Field>
          </div>

          <div className="flex w-1/2 items-start">
            <Field key="secretCode" label={t(data).FORM.SECRET_CARD_TITLE} warning={setWarning('secretCode')} required>
              <MaskedInput
                className="text-fill-primary w-full bg-transparent font-body text-base leading-tight text-primary focus:outline-none focus-visible:outline-none"
                maxLength={countCvv}
                mask={createMask('NUMBER', countCvv)}
                placeholder={t(data).FORM.SECRET_CARD_PLACEHOLDER}
                type="password"
                value={formik.values.secretCode}
                onChange={(e: ChangeEvent<any>) => {
                  const cardNumber = e.target.value.replace(regexMaskCard, '');
                  formik.setFieldValue('secretCode', cardNumber);
                }}
                onBlur={() => formik.setFieldTouched('secretCode', true)}
                pattern="[0-9]*"
                guide={false}
                inputMode="numeric"
              />
            </Field>
            <button
              className="flex h-8 w-8 flex-shrink-0 justify-center"
              type="button"
              onClick={() => setModalOpen(true)}
            >
              <img className="h-3 w-3" alt="Info" src={getPublicURL(`/theme/assets/images/icon/info.svg`)} />
            </button>
          </div>
        </section>

        <Field key="cardOwner" label={t(data).FORM.CARD_OWNER_TITLE} warning={setWarning('cardOwner')} required>
          <MaskedInput
            className="text-fill-primary w-full bg-transparent font-body text-base leading-tight text-primary focus:outline-none focus-visible:outline-none"
            placeholder={t(data).FORM.CARD_OWNER_PLACEHOLDER}
            value={formik.values.cardOwner}
            onChange={formik.handleChange('cardOwner')}
            onBlur={() => formik.setFieldTouched('cardOwner', true)}
            mask={createMask('NAME', 30)}
            guide={false}
            inputMode="text"
          />
        </Field>

        <div className="pt-2"></div>

        <Switch id="saveCard" checked={formik.values.saveCard} onChange={formik.handleChange('saveCard')} toTop>
          <H4 className="pr-8 text-gray">
            ¿Quieres que guardemos los datos de tu tarjeta de forma segura para futuras transacciones?
          </H4>
        </Switch>

        <div className="pt-2"></div>

        {infoUser.amount && (
          <Field key="amount" label="Importe" readOnly>
            <input
              className="text-fill-primary w-full bg-transparent font-body text-base leading-tight text-primary focus:outline-none focus-visible:outline-none"
              value={currencyFormatter(currency, infoUser.amount)}
              disabled={true}
            />
          </Field>
        )}
      </section>

      <Button className="mx-auto mt-5" type="submit"  disabled={!formik.isValid}>
        {t(data).FORM.PAY_BUTTON_TITLE}
      </Button>

      <Modal
        open={modalOpen}
        onClose={() => {
          setModalOpen(false);
        }}
      >
        <section className="text-center text-gray">
          <H2 className="mb-2">CVV</H2>
          <P>
            Es un <b>código de seguridad</b> que corresponde a los 3 últimos dígitos del reverso de la tarjeta. Si
            dispones de un CVV dinámico tu banco te proporcionará este código. Este dato no será guardado por la
            compañía.
          </P>
        </section>
      </Modal>
    </form>
  );
};

export { FormNewCard };
